import React, { useState } from 'react'
import ImageWrap from 'components/ImageWrap'
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { StaticImage } from 'gatsby-plugin-image'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'
// import ArrowLong from 'components/ArrowLong'

SwiperCore.use([Autoplay, Navigation, Pagination])

const Slideshow = ({ content, locale }) => {
  const { items } = content
  const [swiper, setSwiper] = useState(null)
  const [textIndex, setTextIndex] = useState(0)
  const handleSlideChange = () => {
    if (swiper) {
      setTextIndex(swiper.realIndex)
    }
  }

  return (
    <Inview className={`grid-24 ${styles.swiperComponent} fade-in up`}>
      <div className={styles.containerInner}>
        <SwiperImages
          items={items}
          setSwiper={setSwiper}
          handleSlideChange={handleSlideChange}
        />
        <SwiperText items={items} locale={locale} textIndex={textIndex} />
      </div>
    </Inview>
  )
}

const SwiperImages = ({ items, setSwiper, handleSlideChange }) => {
  let initialSlidesPerView = 1
  if (typeof window !== 'undefined' && window.innerWidth >= 1025) {
    initialSlidesPerView = 2
  }
  return (
    <>
      <Swiper
        onSwiper={setSwiper}
        onSlideChange={handleSlideChange}
        className={styles.swiperImages}
        navigation={{
          nextEl: '.swiper-button-next',
        }}
        pagination={{
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        }}
        loop={true}
        loopAdditionalSlides={items.length - initialSlidesPerView}
        speed={600}
        autoplay={{
          delay: 5000,
        }}
        spaceBetween={12}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          601: {
            slidesPerView: 1.5,
          },
          1025: {
            slidesPerView: 2,
          },
        }}
      >
        <div className={`swiper-button-next ${styles.arrowWrap}`}>
          <StaticImage src='../../images/icons/slideshow-button.png' alt='' />
        </div>

        <div className={styles.paginationWrap}>
          <div className='swiper-pagination' />
        </div>

        {items?.map((item, i) => (
          <SwiperSlide key={i}>
            {({ isActive }) => (
              <SlideImage content={item} isActive={isActive} />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}

const SwiperText = ({ items, locale, textIndex, isExcerptOnly }) => {
  let maxDescriptionLength = 0
  let placeholderSlide = items[0]
  items.forEach((item) => {
    const measure = item.description?.length
    if (measure > maxDescriptionLength) {
      maxDescriptionLength = measure
      placeholderSlide = item
    }
  })

  return (
    <div className={styles.swiperText}>
      {items?.map((item, i) => {
        const isActive = i === textIndex
        return (
          <SlideText
            content={item}
            locale={locale}
            isActive={isActive}
            key={i}
          />
        )
      })}
      <SlideText
        content={placeholderSlide}
        locale={locale}
        isPlaceholder={true}
      />
    </div>
  )
}

const SlideImage = ({ content, isActive }) => {
  const { image, link } = content
  const isActiveClass = isActive ? styles.isActive : ''
  return (
    <div className={`${styles.slideImageInner} ${isActiveClass}`}>
      <ImageWrap image={image} />
    </div>
  )
}

const SlideText = ({ content, isActive, isPlaceholder }) => {
  const { title, description } = content
  const isActiveClass = isActive ? styles.isActive : ''
  const isPlaceholderClass = isPlaceholder ? styles.isPlaceholder : ''
  return (
    <div
      className={`${styles.slideText} ${isActiveClass} ${isPlaceholderClass} p-smaller`}
    >
      {title && <h2>{title}</h2>}
      <p>{description}</p>
    </div>
  )
}

export default Slideshow

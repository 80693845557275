import React from 'react'
import { Link } from 'gatsby'

const LineLink = ({ to, text, external }) => {
  if (external) {
    return (
      <a
        href={to}
        target='_blank'
        rel='noopener noreferrer'
        className='line-link'
      >
        <span className='inner'>{text}</span>
      </a>
    )
  } else {
    return (
      <Link to={to} className='line-link'>
        <span className='inner'>{text}</span>
      </Link>
    )
  }
}

export default LineLink

import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Inview from 'components/Inview'

const BgLink = (props) => {
  const {
    to,
    text,
    external,
    isButton,
    isFilledGreen,
    isWhite,
    isCentered,
    isDownload,
    hasDelay = true,
  } = props

  const hasDelayClass = hasDelay ? 'stagger-500' : ''

  return (
    <Inview>
      {external && (
        <a
          href={to}
          target='_blank'
          rel='noopener noreferrer'
          className={`
            bg-link styled'
            ${isButton ? ' button' : ''}
            ${isCentered ? ' is-centered' : ''}
            ${isFilledGreen ? ' filled-green' : ''}
            ${isWhite ? ' white' : ''}
            fade-in ${hasDelayClass}
            `}
        >
          <Inner text={text} isDownload={isDownload} />
        </a>
      )}
      {!external && (
        <Link
          to={to}
          className={`
            bg-link styled'
            ${isButton ? ' button' : ''}
            ${isCentered ? ' is-centered' : ''}
            ${isFilledGreen ? ' filled-green' : ''}
            ${isWhite ? ' white' : ''}
            fade-in ${hasDelayClass}
            `}
        >
          <Inner text={text} isDownload={isDownload} />
        </Link>
      )}
    </Inview>
  )
}

const Inner = ({ text, isDownload }) => {
  return (
    <>
      <span className='inner text'>
        {isDownload && (
          <StaticImage src='../../images/icons/download.png' alt='' />
        )}
        {text}
      </span>
      <span className='inner  bg'>
        <span className='inner-text'>
          {isDownload && (
            <StaticImage src='../../images/icons/download-white.png' alt='' />
          )}
          {text}
        </span>
      </span>
    </>
  )
}

export default BgLink

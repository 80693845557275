import React from 'react'
import { graphql } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import * as styles from './esg.module.scss'

import Banner from 'components/Banner'
import Breadcrumb from 'components/Breadcrumb'
import LeftTextSlideshow from 'components/LeftTextSlideshow'
import ImageWrap from 'components/ImageWrap'
import BgLink from 'components/BgLink'
import Inview from 'components/Inview'
import Layout from 'components/Layout'
import Seo from 'components/Seo'

import { tr } from 'js/translations.js'

const Page = ({ data }) => {
  const locale = process.env.GATSBY_LOCALE
  const page = data['page'].nodes[0]

  const breadcrumb = [
    {
      text: tr('HOME', locale),
      link: '/',
    },
    {
      text: page.title,
    },
  ]
  const introText = renderRichText(page.introText)
  const textImage = renderRichText(page.textImage)
  const textOnly = renderRichText(page.textOnly)
  const textImage2 = renderRichText(page.textImage2)
  const textBgImage = renderRichText(page.textBgImage)
  const slideshow = {
    items: page.slideshow.map((item) => {
      return {
        image: item.image,
        description: item.description.description,
      }
    }),
  }

  return (
    <Layout>
      <div className={styles.page}>
        <Seo page={page} locale={locale} />
        <Banner image={page.banner} locale={locale} />
        <Breadcrumb items={breadcrumb} />

        <div className='wrapper pad-sides'>
          <div className='inner'>
            {/* Intro Text */}
            <Inview className={styles.introText}>
              <div className={styles.inner}>
                <div className='fade-in slower stagger-500'>{introText}</div>
              </div>
            </Inview>

            {/* Text Image 1 */}
            <Inview className={styles.textImage}>
              <div className={`${styles.inner} grid-24`}>
                <div className={`${styles.border} fade-in  stagger-500`} />
                <div
                  className={`${styles.text} fade-in-up stagger-500 p-smaller`}
                >
                  {textImage}
                </div>
                <div className={`${styles.image} fade-in`}>
                  <ImageWrap image={page.textImage.references[0]} alt='' />
                </div>
              </div>
            </Inview>
            {/* Slideshow */}
            <div className={styles.slideshow}>
              <LeftTextSlideshow content={slideshow} />
            </div>
            {/* Text Only */}
            <Inview className={styles.textOnly}>
              <div className={`${styles.inner} fade-in grid-24`}>
                <div className={`${styles.text} p-smaller`}>
                  <div className={styles.border} />
                  {textOnly}
                </div>
              </div>
            </Inview>

            {/* Text Image 2 */}
            <Inview className={styles.textImage}>
              <div className={`${styles.inner} grid-24`}>
                <div className={`${styles.border} fade-in`} />
                <div className={`${styles.text} fade-in-up p-smaller`}>
                  {textImage2}
                </div>
                <div className={`${styles.image} fade-in`}>
                  <ImageWrap image={page.textImage2.references[0]} alt='' />
                </div>
              </div>
            </Inview>
          </div>
        </div>
        {/* Text Bg Image */}
        <Inview className={styles.textBgImage}>
          <div className={`${styles.image} fade-in abs-fill`}>
            <ImageWrap image={page.textBgImage.references[0]} alt='' />
          </div>
          <div className={`${styles.content} fade-in-up stagger-500`}>
            <div className='pad-sides'>
              <div className='inner'>
                <div className={styles.textWrap}>
                  <div className={styles.text}>
                    {textBgImage}
                    <BgLink
                      to={page.downloadFile.localFile.url}
                      text={`${tr('DOWNLOAD_REPORT', locale)}`}
                      external={true}
                      isDownload={true}
                      hasDelay={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Inview>
      </div>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    page: allContentfulEsgPage {
      nodes {
        title
        banner {
          ...GatsbyImage
        }
        introText {
          raw
        }
        textImage {
          raw
          references {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        slideshow {
          description {
            description
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  aspectRatio: 1.5
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
        }
        textOnly {
          raw
        }
        textImage2 {
          raw
          references {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        textBgImage {
          raw
          references {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        downloadFile {
          localFile {
            url
          }
        }
        seo {
          ...SeoFields
        }
      }
    }
  }
`

// extracted by mini-css-extract-plugin
export var arrowWrap = "styles-module--arrow-wrap--OEhPk";
export var containerInner = "styles-module--container-inner--gI0Qh";
export var isActive = "styles-module--is-active--KWAkD";
export var isPlaceholder = "styles-module--is-placeholder--MNBVk";
export var paginationWrap = "styles-module--pagination-wrap--Q7RE8";
export var slideImageInner = "styles-module--slide-image-inner--jhF0y";
export var slideText = "styles-module--slide-text--ag8zg";
export var swiperComponent = "styles-module--swiper-component--mMsU5";
export var swiperImages = "styles-module--swiper-images--Z+liM";
export var swiperText = "styles-module--swiper-text--G7Fak";
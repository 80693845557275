// extracted by mini-css-extract-plugin
export var border = "esg-module--border--cBHwD";
export var content = "esg-module--content--EaEWS";
export var image = "esg-module--image--lYobx";
export var inner = "esg-module--inner--a1G-V";
export var introText = "esg-module--intro-text--sh7Jf";
export var page = "esg-module--page--BuiDN";
export var slideshow = "esg-module--slideshow--6upcu";
export var text = "esg-module--text--mRI3G";
export var textBgImage = "esg-module--text-bg-image--uZAhl";
export var textImage = "esg-module--text-image--xRthu";
export var textOnly = "esg-module--text-only--6fKmh";
export var textWrap = "esg-module--text-wrap--c1yeR";